body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #app, #root, #app>div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Circular';
  src: url("./fonts/CircularStd-Light.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url("./fonts/CircularStd-Medium.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url("./fonts/CircularStd-Bold.otf") format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Circular';
  src: url("./fonts/CircularStd-Black.otf") format('opentype');
  font-weight: 700;
}

h1 {

  font-weight: 700;
  margin: 0 !important;

  @media (min-width: 992px) {
    font-size: calc(1.375rem + ((1vw - 9.92px) * 1.9685));
  }
  @media (min-width: 1500px) {
    font-size: 32px;
  }
}

h2 {

  font-weight: 700;
  margin: 0 !important;
  line-height: 1;

  @media (min-width: 992px) {
    font-size: calc(1.375rem + ((1vw - 9.92px) * 1.9685));
  }
  @media (min-width: 1500px) {
    font-size: 32px;
  }
}

h3 {

  font-weight: 400;
  margin: 0;

  @media (min-width: 992px) {
    font-size: calc(18px + ((1vw - 9.92px) * 0.5388));
  }
  @media (min-width: 1500px) {
    font-size: 20px;
  }
}

h4 {

  font-weight: 500;
  margin: 0;

  @media (min-width: 992px) {
    font-size: calc(0.875rem + ((1vw - 9.92px) * 0.5906));
  }
  @media (min-width: 1500px) {
    font-size: 17px;
  }
}

h5 {

  font-weight: 500;
  margin: 0;

  @media (min-width: 992px) {
    font-size: calc(0.75rem + ((1vw - 9.92px) * 0.3937));
  }
  @media (min-width: 1500px) {
    font-size: 14px;
  }
}
